<template>
    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
        <SmartTable ref="storicoaffiliazioni" url="/sportingclub/affiliation-history/index"
            base_path="/" :fixed_filters="[
                { name: 'idsportingclub', filter: idsportingclub },
            ]" custom_class="mx-0 px-0">
            <template v-slot:title>
                <h2 class="card-title">
                    <span class="card-label font-weight-bolder text-dark font-size-h3">Storico
                        Affiliazioni</span>
                </h2>
            </template>

            <template v-slot:td-idcommittee="slotProps">
                <td>
                    <div class="d-flex align-items-center">
                        <div>
                            <span v-if="
                                typeof slotProps.values.item['force_idcommittee'] ===
                                'undefined'
                            " class="text-dark-75 text-hover-primary mb-1 font-size-lg">{{
                                slotProps.values.item[slotProps.values.field.name]
                            }}</span>

                            <span v-if="
                                typeof slotProps.values.item['force_idcommittee'] !==
                                'undefined'
                            " class="text-dark-75 text-hover-primary mb-1 font-size-lg">{{
                                slotProps.values.item["force_idcommittee"] }}</span>
                        </div>
                    </div>
                </td>
            </template>
        </SmartTable>
    </b-overlay>
</template>

<script>
 import SmartTable from "@/view/components/SmartTable.vue";
 import ApiService from "@/core/services/api.service";

 export default {
     props: ['idsportingclub', 'annualityid'],

     data() {
         return {
             loading: false,
             tabIndex: 0,
         };
     },

     components: {
         SmartTable,
     },
 };
</script>

<style>
 .border-bluenavy .card-header {
	 border-bottom: 1px solid #143D67;
 }
</style>
