<template>
    <div>
        <b-card no-body>
            <b-tabs card v-model="tabIndex">
                <b-tab class="border border-primary border-top-0 rounded-bottom" title="Monitoraggio Invii"
                    :title-link-class="linkClass(0)">
                    <SmartTable v-if="idsportingclub" ref="monitoraggioinvii" url="/rsmonitoring/index"
                        :is_dialog="true" base_url="/rsmonitoring" base_path="/"
                        :fixed_filters="[{ name: 'idsportingclub', filter: idsportingclub }]" custom_class="mx-0 px-0"
                        v-on:close-modal="closeModal">

                        <template v-slot:title>
                            <h2 class="card-title"><span
                                    class="card-label font-weight-bolder text-dark font-size-h3">Monitoraggio
                                    Invii</span></h2>
                        </template>

                        <template v-slot:td-step="slotProps">
                            <td>
                                <div class="d-flex align-items-center flex-column">
                                    <div>
                                        <span class="text-dark-75 text-hover-primary mb-1 font-size-lg">{{
                                            slotProps.values.item.step }}</span>
                                    </div>

                                    <div v-if="(typeof slotProps.values.item.step !== 'undefined') && (slotProps.values.item.step === 'INVIA_DOCUMENTI') && (typeof slotProps.values.item.objectparam !== 'undefined') && (slotProps.values.item.objectparam instanceof Array) && (slotProps.values.item.objectparam.length)"
                                        class="d-flex align-items-center">
                                        <span v-for="documento in slotProps.values.item.objectparam"
                                            class="label font-weight-bold label-inline mr-1 mt-1 label-primary"
                                            style="font-size: 1rem; padding: .3rem 1rem; height: auto !important;">{{
                                                documento }}</span>
                                    </div>
                                </div>
                            </td>
                        </template>

                        <template v-slot:item-actions="slotProps">
                            <td class="text-nowrap pr-0 text-center">
                                <a v-if="!slotProps.values.item.errormessage" href="javascript:void(0);"
                                    class="btn btn-icon btn-circle btn-sm btn-success mr-3" title="Dettagli"
                                    alt="Dettagli"
                                    @click.prevent="slotProps.values.doItemAction(slotProps.values.item.actions[0], slotProps.values.item.id)">
                                    <i class="fas fa-eye"></i>
                                </a>

                                <a v-if="slotProps.values.item.errormessage" href="javascript:void(0);"
                                    class="btn btn-icon btn-circle btn-sm btn-danger mr-3" title="Dettagli"
                                    alt="Dettagli"
                                    @click.prevent="slotProps.values.doItemAction(slotProps.values.item.actions[0], slotProps.values.item.id)">
                                    <i class="fas fa-exclamation-triangle"></i>
                                </a>
                            </td>
                        </template>
                    </SmartTable>
                </b-tab>

                <b-tab class="border border-primary border-top-0 rounded-bottom" title="Affiliazioni"
                    :title-link-class="linkClass(1)">
                    <ManageRsAffiliation :idsportingclub="idsportingclub" :annualityid="annualityid">
                    </ManageRsAffiliation>
                </b-tab>

                <b-tab class="border border-primary border-top-0 rounded-bottom" title="Consiglio"
                    :title-link-class="linkClass(2)">
                    <ManageRsCouncil :idsportingclub="idsportingclub" :annualityid="annualityid"></ManageRsCouncil>
                </b-tab>

                <b-tab class="border border-primary border-top-0 rounded-bottom" title="Documenti"
                    :title-link-class="linkClass(3)">
                    <ManageRsDocument :idsportingclub="idsportingclub" :annualityid="annualityid"></ManageRsDocument>
                </b-tab>

                <b-tab class="border border-primary border-top-0 rounded-bottom" title="Tesseramenti"
                    :title-link-class="linkClass(4)">
                    <ManageRsEnrolledUser :idsportingclub="idsportingclub" :annualityid="annualityid">
                    </ManageRsEnrolledUser>
                </b-tab>

                <b-tab class="border border-primary border-top-0 rounded-bottom" title="Modifica Dati Sodalizio"
                    :title-link-class="linkClass(5)">
                    <SportingClubForm ref="modificaSodalizioForm" base_url="/sportingclub/sportingclub-change"
                        base_path="/sportingclub/sportingclub-change"
                        :fixed_filter="[{ name: 'idannuality', filter: annualityid }]" form_type="edit"
                        :redirect_after_save="false" :item_id="idsportingclub" v-on:values-saved="aggiornaSodalizio">
                        <template v-slot:title>
                            <h3 class="card-label">Modifica Sodalizio</h3>
                        </template>
                        <template v-slot:toolbar>
                            <span></span>
                        </template>
                        <template v-slot:footer>
                            <span></span>
                        </template>
                    </SportingClubForm>
                </b-tab>

                <b-tab class="border border-primary border-top-0 rounded-bottom" title="Stato Iscrizione Registro"
                    :title-link-class="linkClass(6)">
                    <ManageRsSubscription :item="item">
                    </ManageRsSubscription>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import SmartTable from "@/view/components/SmartTable.vue";
import ApiService from "@/core/services/api.service";
import SportingClubForm from "@/view/components/SportingClubForm.vue";
import ManageRsDocument from "@/view/components/ManageRsDocument.vue";
import ManageRsCouncil from "@/view/components/ManageRsCouncil.vue";
import ManageRsAffiliation from "@/view/components/ManageRsAffiliation.vue";
import ManageRsEnrolledUser from "@/view/components/ManageRsEnrolledUser.vue";
import ManageRsSubscription from "@/view/components/ManageRsSubscription.vue";

export default {
    props: ['idsportingclub', 'annualityid', 'values', 'item'],

    data() {
        return {
            tabIndex: 0,
        };
    },

    components: {
        SmartTable,
        SportingClubForm,
        ManageRsDocument,
        ManageRsCouncil,
        ManageRsAffiliation,
        ManageRsEnrolledUser,
        ManageRsSubscription,
    },

    created() {
    },

    computed: {
    },

    mounted() {
    },

    methods: {
        linkClass(idx) {
            if (this.tabIndex === idx) {
                return ['text-primary', 'border-top-primary', 'border-left-primary', 'border-right-primary', 'text-center', 'font-size-h5']
            } else {
                return ['bg-primary', 'text-white', 'font-size-h5']
            }
        },

        closeModal() {
        },

        aggiornaSodalizio() {
        },

        findField(obj, name) {
            if (typeof obj !== "undefined" && obj && typeof obj.fields !== "undefined" && obj.fields) {
                return obj.fields.find(item => item.name === name);
            }

            return null;
        },

        getFieldValue(field) {
            if (field) {
                let value = typeof field.value !== "undefined" ? field.value : null;

                if (value !== null) {
                    return (JSON.parse(JSON.stringify(value)));
                }
            }

            return null;
        },

        setFieldVisible(obj, name, visible) {
            if (typeof obj.fields !== "undefined") {
                let fieldIndex = obj.fields.findIndex(item => item.name === name);
                if (fieldIndex) {
                    if (typeof obj.fields !== "undefined" && typeof obj.fields[fieldIndex] !== "undefined") this.$set(obj.fields[fieldIndex], 'is_visible_for_store', visible);
                    if (typeof obj.fields !== "undefined" && typeof obj.fields[fieldIndex] !== "undefined") this.$set(obj.fields[fieldIndex], 'is_visible_for_update', visible);
                }
            }
        },

        setFieldAttribute(obj, name, attribute, value) {
            let fieldIndex = obj.fields.findIndex(item => item.name === name);
            if (fieldIndex) {
                this.$set(obj.fields[fieldIndex], attribute, value);
            }
        },

        loadOptions(obj, type, parameterName, parameterValue, fieldName, callbackEmptyOption, callbackOptions = null) {
            let url = '/sportingclub/load-options?type=' + type + '&' + parameterName + '=' + parameterValue;

            ApiService.query(url)
                .then((response) => {
                    let options = response.data;

                    if (typeof options === "undefined") options = [];

                    callbackEmptyOption(options);

                    if (callbackOptions !== null) {
                        callbackOptions(options);
                    }
                    else {
                        this.setFieldAttribute(obj, fieldName, 'filter_options', options);
                    }
                })
                .catch((error) => {
                    console.log(error);

                    let options = [];

                    callbackEmptyOption(options);

                    if (callbackOptions !== null) {
                        callbackOptions(options);
                    }
                    else {
                        this.setFieldAttribute(obj, fieldName, 'filter_options', options);
                    }
                })
        },
    }
};
</script>

<style>
.border-bluenavy .card-header {
    border-bottom: 1px solid #143D67;
}
</style>
