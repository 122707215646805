var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm","opacity":"0.50","blur":"blur","spinner-variant":"primary"}},[_c('SmartTable',{ref:"storicoaffiliazioni",attrs:{"url":"/sportingclub/affiliation-history/index","base_path":"/","fixed_filters":[
            { name: 'idsportingclub', filter: _vm.idsportingclub } ],"custom_class":"mx-0 px-0"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h2',{staticClass:"card-title"},[_c('span',{staticClass:"card-label font-weight-bolder text-dark font-size-h3"},[_vm._v("Storico Affiliazioni")])])]},proxy:true},{key:"td-idcommittee",fn:function(slotProps){return [_c('td',[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[(
                            typeof slotProps.values.item['force_idcommittee'] ===
                            'undefined'
                        )?_c('span',{staticClass:"text-dark-75 text-hover-primary mb-1 font-size-lg"},[_vm._v(_vm._s(slotProps.values.item[slotProps.values.field.name]))]):_vm._e(),(
                            typeof slotProps.values.item['force_idcommittee'] !==
                            'undefined'
                        )?_c('span',{staticClass:"text-dark-75 text-hover-primary mb-1 font-size-lg"},[_vm._v(_vm._s(slotProps.values.item["force_idcommittee"]))]):_vm._e()])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }